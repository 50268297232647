import React, { useCallback, useRef, useState } from 'react';
import L, { LatLng } from 'leaflet';
import { Box, OSKIcon, TextInput, OSKThemeType, Spinner, BoxProps, Typography, List } from 'oskcomponents';
import { debounce } from 'lodash';
import { geocode } from '../../geocode';
import styled, { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { parseCoordinates } from '~/utils';

export type GeocodingLatLng = {
    lat: number;
    lng: number;
};

export type GeocodingBounds = {
    northeast: GeocodingLatLng;
    southwest: GeocodingLatLng;
};

type GeocodingTextBoxProps = {
    /** A method that's called when a location has been selected */
    onSelected?: (point: L.LatLng, bounds?: GeocodingBounds) => void;
} & Omit<BoxProps, 'ref'>;

const GeocodingSearch = ({ onSelected, ...props }: GeocodingTextBoxProps) => {
    const theme = useTheme() as OSKThemeType;
    const ref = useRef<HTMLInputElement>(null);
    const refSize = ref.current?.getBoundingClientRect();

    const [text, setText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [geocodeResults, setGeocodeResults] = useState<google.maps.GeocoderResult[]>([]);

    const clearButtonVisible = text !== '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGeoQuery = useCallback(
        debounce((q) => {
            setLoading(true);
            geocode(q)
                .then((result) => {
                    setGeocodeResults(result.results);
                })
                .finally(() => setLoading(false));
        }, 800),
        [],
    );

    function processTextbox(query: string) {
        const point = parseCoordinates(query);

        if (point) {
            onSelected && onSelected(point);
        } else {
            debouncedGeoQuery(query);
        }

        setText(query);

        if (ref.current) {
            ref.current.value = query;
        }
    }

    return (
        <Box style={{ width: '100%', zIndex: GlobalZIndex.ActionBar }} {...props} col>
            <Box style={{ position: 'relative' }} center="vertical">
                <TextInput
                    variant="contrast"
                    ref={ref}
                    name={''}
                    style={{ width: '100%' }}
                    icon={
                        loading ? (
                            <Spinner size="Tiny" variant="Circle" style={{ marginLeft: '0px' }} />
                        ) : (
                            <OSKIcon code="globe" height={44} fill="black" />
                        )
                    }
                    placeholder="Find in map. e.g. Gulf of Mexico or a GPS point"
                    value={text}
                    onChange={(evt) => {
                        if ('inputType' in evt.nativeEvent && 'data' in evt.nativeEvent) {
                            // @ts-ignore
                            const { inputType, data, target } = evt.nativeEvent;
                            const query = target && 'value' in target ? target['value'] : data;

                            if (query) {
                                const hasPoint = parseCoordinates(query as string);
                                if (inputType === 'insertFromPaste') {
                                    processTextbox(query as string);
                                } else if (!hasPoint) {
                                    debouncedGeoQuery(evt.target.value);
                                }
                            }
                        }

                        setText(evt.target.value);
                    }}
                    onKeyDown={(evt) => {
                        if (evt.key === 'Enter') {
                            // Try parsing
                            // @ts-ignore
                            processTextbox(evt.target.value);
                            evt.stopPropagation();
                            evt.preventDefault();
                        }
                    }}
                />
                {clearButtonVisible && (
                    <Box
                        center="all"
                        style={{ position: 'absolute', right: '8px', height: '30px', paddingLeft: '4px' }}
                        onClick={() => {
                            setText('');
                            setGeocodeResults([]);
                        }}
                        bg="white"
                    >
                        <OSKIcon code="times-circle" fill={'black'} />
                    </Box>
                )}
            </Box>

            {geocodeResults.length > 0 && (
                <Box
                    style={{
                        zIndex: GlobalZIndex.ActionBar + 1,
                        position: 'absolute',
                        top: `${(refSize?.y ?? 0) - (refSize?.height ?? 0) + 10}px`,
                        borderRadius: '7px',
                        border: `1px solid ${theme.colors.black200}`,
                        overflow: 'hidden',
                    }}
                    col
                >
                    <Box bg="white" style={{ width: '100%', height: '8px' }} />
                    <Box
                        col
                        style={{
                            borderRadius: '0px 0px 5px 5px',
                            backgroundColor: theme.colors.white,
                            overflow: 'hidden',
                            width: `${ref.current?.offsetWidth}px`,
                            borderTop: '',
                            filter: `drop-shadow(0 5px 2px ${theme.colors.gray3a} )`,
                        }}
                        {...props}
                    >
                        <GeocodingList
                            items={geocodeResults.map((result) => {
                                const title = result.formatted_address;
                                const caption = result.address_components[0].short_name;

                                return {
                                    label: (
                                        <Box center="vertical">
                                            <Box
                                                center="all"
                                                style={{
                                                    backgroundColor: theme.colors.accent,
                                                    width: '34px',
                                                    height: '34px',
                                                    borderRadius: '34px',
                                                    marginRight: '8px',
                                                }}
                                            >
                                                <OSKIcon code="map" fill="white" />
                                            </Box>
                                            <Box col>
                                                <Typography variant="heading5">{title}</Typography>
                                                {title !== caption && (
                                                    <Typography variant="body4">{caption}</Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    ),
                                    value: result,
                                };
                            })}
                            itemStyle={{ padding: '8px 14px' }}
                            variant="contrast"
                            onSelect={(item: any) => {
                                setGeocodeResults([]);

                                if (item) {
                                    setText(item.value.formatted_address);

                                    // Extract coordinates
                                    const { location, bounds } = item.value.geometry;
                                    if (onSelected && location) {
                                        onSelected(L.latLng(location.lat, location.lng), bounds);
                                    }
                                }
                            }}
                        />
                        <Box bg="white" style={{ width: '100%', height: '8px' }} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const GeocodingList = styled(({ className, ...props }: any) => {
    return <List className={className} {...props} />;
})`
    & div[role='listitem'] {
        border: 1px solid transparent;
    }

    & div[data-selected] {
        border: 1px solid green;
        background-color: ${(props: any) => props.theme.colors.gray25};
        border: 1px solid ${(props: any) => props.theme.colors.gray50};
        color: black;
    }
`;

export default GeocodingSearch;
