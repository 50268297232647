import { Box } from 'oskcomponents';
import { NavComponent, OSKView } from 'oskcore';
import React from 'react';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { Toaster } from 'react-hot-toast';

type OSKToastViewProps = {
    /** Optional flag to hide the gradient */
    nogradient?: boolean;
    /** Child node passthrough */
    children?: Array<React.ReactNode> | React.ReactNode;
};

const OSKToastView = ({ children, nogradient = false, ...props }: OSKToastViewProps) => {
    return (
        <OSKView constrained nopadding nogradient={nogradient}>
            <Box style={{ flexGrow: 1, height: '100%' }}>
                {children}
            </Box>
            <Toaster position='bottom-center' containerStyle={{bottom: '50px'}} />
        </OSKView>
    );
};

export { OSKToastView };
export type { OSKToastViewProps };
