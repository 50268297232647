import React, { useEffect, useState } from 'react';
import { Box, NullableDate, Pagination, Spinner, Table, Text } from 'oskcomponents';
import { EclipsedMode, setSelectedOpportunity } from '~/redux/modules/tasking/app';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '~/redux/store';
import { noop, Opportunity, OSKGeoJson, Sensor, SigmaAPI } from 'oskcore';
import styled from 'styled-components';
import { utc_date_format } from '~/utils';
import { calculateRollAngle } from '~/utils/rollAngleUtils';

export type OpportunityWindowTableProps = {
    /** From redux, data for all current sensors. */
    sensors: Array<Sensor>;
    /** From redux, the currently selected AOI */
    selectedAoi?: OSKGeoJson;
    /** From redux, the start date for tasking search */
    taskingSearchStartDate: NullableDate;
    /** From redux, the end date for tasking search */
    taskingSearchEndDate: NullableDate;
    /** The "eclipsed" mode for the tasking request */
    taskingSearchEclipsedMode: EclipsedMode;
};

const StyledTable = styled<any>(Table)`
    tbody tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.black200} !important;
    }

    tbody tr:hover {
        td {
            background-color: ${(props: any) => props.theme.colors[props.variant ?? 'primary'].highlight} !important;
        }
    }
`;

const OPPORTUNITY_PAGE_SIZE = 20;

export const OpportunityWindowTable = ({
    sensors,
    selectedAoi,
    taskingSearchStartDate,
    taskingSearchEndDate,
    taskingSearchEclipsedMode,
}: OpportunityWindowTableProps) => {
    const dispatch = useDispatch();
    const [loadingOps, setLoadingOps] = useState<'' | 'loading' | 'success' | 'failed'>('');
    const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
    const [totalOpportunities, setTotalOpportunities] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const [selectedRow, setSelectedRow] = useState<number>(0);
    const center_point = selectedAoi?.getCentroid();

    useEffect(() => {
        if (selectedAoi) {
            setLoadingOps('loading');
            setError(undefined);
            SigmaAPI.searchOrbits({
                limit: OPPORTUNITY_PAGE_SIZE,
                searchTimeStart: taskingSearchStartDate?.toISOString(),
                searchTimeEnd: taskingSearchEndDate?.toISOString(),
                aoi: JSON.stringify(selectedAoi.toAPIGeometry()) as any,
                offset: OPPORTUNITY_PAGE_SIZE * currentPage,
                ...(taskingSearchEclipsedMode !== 'all' ? { eclipsed: taskingSearchEclipsedMode === 'night' } : {}),
            })
                .then((response) => {
                    setTotalOpportunities(response.data.count ?? 0);
                    setLoadingOps('success');
                    setOpportunities(response.data.results ?? []);
                    if (response.data.results) {
                        dispatch(setSelectedOpportunity(response.data.results[0]));
                    }
                })
                .catch((err) => {
                    setLoadingOps('failed');
                    console.info(err);
                    setError(err);
                });
        }
    }, [selectedAoi, currentPage, taskingSearchEclipsedMode]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Estimated Start',
                accessor: 'start_time',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return <Text variant="small">{utc_date_format(value)}</Text>;
                },
            },
            {
                Header: 'Estimated End',
                accessor: 'end_time',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return <Text variant="small">{utc_date_format(value)}</Text>;
                },
            },
            {
                Header: 'Roll Angle',
                accessor: 'max_fov',
                Cell: ({ row: { original } }: any) => {
                    const { sensor, max_fov } = original;
                    const db_sensor = sensors.find((platform) => platform.osk_id == sensor);

                    if (db_sensor && center_point && db_sensor.max_slew_angle) {
                        const roll_angle = calculateRollAngle(
                            -db_sensor.max_slew_angle,
                            db_sensor.max_slew_angle,
                            max_fov,
                            center_point,
                        );

                        if (roll_angle) {
                            return `${roll_angle.toFixed(1).padStart(5, ' ')}°`;
                        } else {
                            return 'N/A';
                        }
                    } else {
                        return 'N/A';
                    }
                },
            },
            {
                Header: 'Source',
                accessor: 'sensor',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return (
                        <Text variant="small">
                            {sensors.find((sensor) => sensor.osk_id === value)?.osk_sensor_name ?? '--'}
                        </Text>
                    );
                },
            },
        ],
        [],
    );
    switch (loadingOps) {
        case 'success':
            if (opportunities.length > 0) {
                return (
                    <Box style={{ width: '100%' }} col center="horizontal">
                        <Box style={{ width: '100%', overflowY: 'scroll' }} col>
                            <StyledTable
                                onRowClick={(row: any, idx: number) => {
                                    setSelectedRow(idx);
                                    dispatch(setSelectedOpportunity(row));
                                }}
                                selectedIndex={selectedRow}
                                sensors={sensors}
                                columns={columns}
                                data={opportunities ?? []}
                                inverted
                            />
                        </Box>
                        <Pagination
                            pageSize={OPPORTUNITY_PAGE_SIZE}
                            offset={currentPage * OPPORTUNITY_PAGE_SIZE}
                            count={totalOpportunities}
                            onChange={(page: number) => setCurrentPage(page)}
                        />
                    </Box>
                );
            } else {
                return (
                    <Box center="all" col grow>
                        <Text style={{ fontSize: '1.75rem' }}>No opportunities found.</Text>
                        <Text variant="small">Please adjust your search criteria.</Text>
                    </Box>
                );
            }

        case 'loading':
            return (
                <Box style={{ width: '100%' }} center="all">
                    <Spinner variant="Box" size="Large" />
                </Box>
            );
        case 'failed':
            return (
                <Box center="all" col grow>
                    <Text style={{ fontSize: '1.75rem' }}>Error retrieving opportunites.</Text>
                    <Text variant="small">{error?.toString()}</Text>
                </Box>
            );
        default:
            return <></>;
    }
};

const mapStateToProps = (state: RootState) => {
    const { taskingSearchStartDate, taskingSearchEndDate, taskingSearchEclipsedMode } = state.tasking.app;

    return {
        sensors: state.osk.sensors,
        selectedAoi: state.data.search.roi,
        taskingSearchStartDate,
        taskingSearchEndDate,
        taskingSearchEclipsedMode,
    };
};
export default connect(mapStateToProps, noop)(OpportunityWindowTable);
